import React, {useCallback, useEffect, useRef, useState} from 'react';
import { View, Text, StyleSheet, TextInput } from 'react-native';
import HTML from 'react-native-render-html';
import ViewShot from 'react-native-view-shot';
import { FormManifestWithData, FormMetadata } from "../../utils/types/formMetadata";
import { getFormTypeFromManifest } from "../../utils/manifests";
import TextEditor from 'components/TextEditor';
import useDebounce from 'react-use/lib/useDebounce';
import {Button} from "native-base";
import {t} from "i18n-js";

interface Option {
    key: string;
    value: string;
}

interface Part {
    title: string;
    description: string;
    type: string;
    options?: string[] | Option[];
}

interface Section {
    title: string;
    parts: {
        [key: string]: Part;
    }[];
}

interface JSONData {
    "storage-version": string;
    common: {
        gender: Option[];
    };
    sections: {
        [key: string]: Section;
    }[];
}

const defaultStyles = `
  body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
  }
  header, footer {
    padding: 20px;
    height: 100px;
    text-align: center;
  }
  header {
    background-color: lightgray;
  }
  footer {
    background-color: darkgray;
    color: white;
  }
  div {
    padding: 20px;
  }
  .last-page {
    background-color: lightblue;
    padding: 20px;
  }
`;

interface Props {
    formMetadata: Partial<FormMetadata>;
    setFormMetadata: React.Dispatch<React.SetStateAction<Partial<FormMetadata>>>
    manifest: FormManifestWithData;
    setTemplate: (form: any) => any;
}

const PDFCustomisation: React.FC<Props> = ({ formMetadata, manifest, setTemplate,setFormMetadata }) => {
    const [header, setHeader] = useState('');
    const [footer, setFooter] = useState('');
    const [lastPage, setLastPage] = useState('');
    const [content, setContent] = useState('');
    const [headerBackground, setHeaderBackground] = useState('#FFFFFF');
    const [footerBackground, setFooterBackground] = useState('#FFFFFF');
    const [lastPageBackground, setLastPageBackground] = useState('#FFFFFF');

    const viewShotRef = useRef<ViewShot>(null);

    const form = manifest.contents.find(value => value.filename.includes('form'));
    const formParsed = getFormTypeFromManifest(manifest);
    const template = manifest.contents.find(value => value.filename.includes('template'));

    const newEditorField = ()=>{
        const htmlContent = parseJSONToHTML(formParsed);
        setContent(htmlContent)
    }


    useEffect(() => {
        if (template) {
            const templateData = template.data
            const parser = new DOMParser();
            const doc = parser.parseFromString(templateData, 'text/html');

            const headerElement = doc.querySelector('header');
            const footerElement = doc.querySelector('footer');
            const lastPageElement = doc.querySelector('section');
            const contentElement = doc.querySelector('div[style*="background-color: #f1f1f1"]');

            if (headerElement) {
                const currentStyle = headerElement.getAttribute('style');
                const backgroundColor = String(currentStyle).match(/background-color:\s*[^;]+;/);

                setHeaderBackground(String(backgroundColor).split(':')[1].trim().replace(';',''));
                headerElement.removeAttribute('style');
            }

            if (footerElement) {
                const currentStyle = footerElement.getAttribute('style');
                const backgroundColor = String(currentStyle).match(/background-color:\s*[^;]+;/);

                setFooterBackground(String(backgroundColor).split(':')[1].trim().replace(';',''));
                footerElement.removeAttribute('style');
            }

            if (lastPageElement) {
                const currentStyle = lastPageElement.getAttribute('style');
                const backgroundColor = String(currentStyle).match(/background-color:\s*[^;]+;/);

                setLastPageBackground(String(backgroundColor).split(':')[1].trim().replace(';',''));
                lastPageElement.removeAttribute('style');
            }

            if (contentElement) {
                setContent(contentElement.innerHTML);
            }


            if (headerElement) {
                setHeader(headerElement.outerHTML);
            }

            if (footerElement) {
                setFooter(footerElement.outerHTML);
            }

            if (lastPageElement) {
                setLastPage(lastPageElement.outerHTML);
            }
        } else {
            newEditorField()
        }
    }, []);


    const parseJSONToHTML = useCallback((json: JSONData): string | undefined => {
        if (json?.sections) {
            return json?.sections.map((section, sectionIndex) =>
                Object.keys(section).map(key =>
                    section[key].parts.map((part, partIndex) =>
                        Object.keys(part).map(partKey =>
                            `<div style="margin-bottom: 15px;"><strong>${part[partKey].title}:</strong> {{sections.${key}.parts.${partKey}.value}}</div>`
                        ).join('')
                    ).join('')
                ).join('')
            ).join('');
        }
    }, []);



    const combinedContent = `
    <html>
      <head>
        <style>
          ${defaultStyles}
        </style>
      </head>
      <body>
        <header style="background-color: ${headerBackground}; min-height: 100px; padding: 20px">${header}</header>
        <div style="background-color: #f1f1f1; padding: 20px">${content}</div>
        <footer style="background-color: ${footerBackground};min-height: 100px; padding: 20px">${footer}</footer>
        <section class="page" style="background-color: ${lastPageBackground}; margin-top: 100px;padding: 20px">${lastPage}</section>
      </body>
    </html>
  `;

    useDebounce(() => {
        setTemplate(combinedContent);
    }, 1000, [combinedContent]);

    useEffect(() => {
        const newForm = { ...formMetadata, pdfHash: template?.sha256 }
        setFormMetadata(newForm)
    }, []);

    return (
        <View style={styles.container}>
            <View style={styles.editorContainer}>
                <EditorWithLabel label={t('template.header')} value={header} onChange={setHeader} maxRows={3} />
                <Text style={styles.label}>{t('template.backgroundColor')}</Text>
                <TextInput
                    style={styles.textInput}
                    value={headerBackground}
                    onChangeText={setHeaderBackground}
                />
                <EditorWithLabel label={t('template.footer')} value={footer} onChange={setFooter} maxRows={3} />
                <Text style={styles.label}>{t('template.backgroundColor')}</Text>
                <TextInput
                    style={styles.textInput}
                    value={footerBackground}
                    onChangeText={setFooterBackground}
                />
                <EditorWithLabel label={t('template.lastPage')} value={lastPage} onChange={setLastPage} maxRows={10} />
                <Text style={styles.label}>{t('template.backgroundColor')}</Text>
                <TextInput
                    style={styles.textInput}
                    value={lastPageBackground}
                    onChangeText={setLastPageBackground}
                />
            </View>
            <Button onPress={()=> newEditorField()}>{t('template.generateNewFields')}</Button>
            <ViewShot ref={viewShotRef} style={styles.previewContainer}>
                <HTML
                    baseStyle={styles.htmlContent}
                    source={{ html: combinedContent }}
                />
            </ViewShot>
        </View>
    );
};

const EditorWithLabel: React.FC<{
    label: string;
    maxRows?: number;
    value: string;
    onChange: (html: string) => void;
}> = ({ label, maxRows, value, onChange }) => (
    <View style={styles.editorContainer}>
        <Text style={styles.label}>{label}</Text>
        <TextEditor value={value} onChange={onChange} maxRows={maxRows} />
    </View>
);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
    },
    editorContainer: {
        marginBottom: 24,
    },
    label: {
        fontWeight: 'bold',
        marginBottom: 8,
    },
    textInput: {
        borderColor: '#ccc',
        borderWidth: 1,
        borderRadius: 4,
        padding: 8,
        marginBottom: 16,
        backgroundColor: '#fff',
    },
    previewContainer: {
        flex: 1,
        marginTop: 16,
    },
    htmlContent: {
        flex: 1,
    },
});

export default PDFCustomisation;
