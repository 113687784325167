import DashboardLayout from 'components/DashboardLayout'
import { useLanguage } from 'components/LanguagePicker'
import { t } from 'i18n-js'
import React from 'react'
import FormSearchResearcher from "../../components/FormSearchResearcher";

export default function FormList({ route, navigation }: any) {
  const { language } = useLanguage()
  return (
    <DashboardLayout
      navigation={navigation}
      displaySidebar={false}
      displayScreenTitle={false}
      title={t('form.select-form')}
    >
      <FormSearchResearcher
        selectItem={formMetadata => {
          navigation.navigate('Statistics', {
            ...route.params,
            formMetadata,
          })
        }}
      />
    </DashboardLayout>
  )
}
