import {t} from "i18n-js";
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import DashboardLayout from '../../components/DashboardLayout';
import { RootStackScreenProps } from '../../utils/formDesigner/navigation';
import {PieChart, pieDataItem} from "react-native-gifted-charts";

type Percentage = {
    name: string;
    percentage: number;
    color: string;
}[];

type ResponseCategory = {
    abstain: number;
    no: number;
    yes: number;
    totalResponses: number;
};

const getPercentageData = (category: ResponseCategory): Percentage => {
    const total = category.totalResponses;
    return [
        { name: 'Yes', percentage: (category.yes / total) * 100, color: 'green' },
        { name: 'No', percentage: (category.no / total) * 100, color: 'red' },
        { name: 'Abstain', percentage: (category.abstain / total) * 100, color: 'yellow' }
    ];
};

const getPercentageDataForChart = (category: ResponseCategory): pieDataItem[] => {
    const total = category.totalResponses;
    return [
        { value: (category.yes / total) * 100, color: 'green', text: 'Yes' },
        { value: (category.no / total) * 100, color: 'red', text: 'No' },
        { value: (category.abstain / total) * 100, color: 'yellow', text: 'Abstain' }
    ];
};

export default function Statistic({ route, navigation }: RootStackScreenProps<'Statistic'>) {
    const renderLegend = (data: Percentage) => (
        data.map((item, index) => (
            <View key={index} style={{ flexDirection: 'row', marginTop: 12, marginBottom: 12,alignItems: 'center',justifyContent: 'center' }}>
                <View style={{
                    height: 18,
                    width: 18,
                    marginRight: 10,
                    borderRadius: 4,
                    backgroundColor: item.color || 'white',
                }} />
                <Text style={{ color: 'black', fontSize: 16 }}>{item.name} {item.percentage.toFixed(2)}%</Text>
            </View>
        ))
    );

    const renderStatistics = (parsedData: any) => {
        return (
            <View style={{backgroundColor: '#fff',padding: 10}}>
                {Object.keys(parsedData).map((sectionKey, sectionIndex) => (
                    <View key={sectionIndex} style={{borderStyle: 'solid', borderWidth: 1, marginBottom: 20, borderRadius: 10, padding: 25}}>
                        <Text style={styles.sectionHeader}>{sectionKey}</Text>
                        <View style={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center',justifyContent: 'space-between'}}>
                                {Object.keys(parsedData[sectionKey]).map((categoryKey, categoryIndex) => {
                                    const category = parsedData[sectionKey][categoryKey];
                                    if (!category) return null;

                                    return (
                                        <View key={categoryIndex} style={styles.statBox}>
                                            <Text style={styles.statName}>{categoryKey}</Text>
                                            <View style={styles.chartContainer}>
                                                <PieChart
                                                    donut
                                                    data={getPercentageDataForChart(category)}
                                                    textSize={18}
                                                    showTextBackground={true}
                                                />
                                                {renderLegend(getPercentageData(category))}
                                            </View>
                                        </View>
                                    );
                                })}
                        </View>
                    </View>
                ))}
            </View>
        );
    };

    return (
        <DashboardLayout
            navigation={navigation}
            displaySidebar={false}
            displayScreenTitle={false}
            title={t('statistic.statistic')}
        >
            {renderStatistics(route.params.parsedData)}
        </DashboardLayout>
    );
}

const styles = StyleSheet.create({
    sectionHeader: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
        marginTop: 20,
    },
    statBox: {
        marginTop: 20,
        width: 250,
    },
    statName: {
        fontSize: 13,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    chartContainer: {
        backgroundColor: '#fff',
        alignItems: 'center',
        flexDirection:'column',
        justifyContent: 'center',
        paddingTop: 20,
    },
});
