import { Box, HStack, Pressable, ScrollView, Text, VStack } from 'native-base'
import React, {useEffect, useState} from 'react'
import { EmailType } from "../utils/types/email";
import {RecordMetadata} from "../utils/types/recordMetadata";

export function ListItem({
   item,
   isSelected,
   selectItem,
}: {
  item: EmailType;
  isSelected: boolean;
  selectItem: (i: EmailType) => any;
}) {
  return (
      <Pressable
          p={2}
          onPress={() => selectItem(item)}
          bg={isSelected ? 'coolGray.200' : 'white'} // Change background color if selected
      >
        <HStack justifyContent="space-between" w="100%">
          <HStack alignItems="center" space={4} w="70%">
            <VStack>
              <Text
                  isTruncated
                  bold
                  fontSize="sm"
                  _light={{ color: 'coolGray.900' }}
              >
                {item.name}
              </Text>
              <Text
                  pl={3}
                  isTruncated
                  fontSize="sm"
                  _light={{ color: 'coolGray.900' }}
              >
                {item.email}
              </Text>
            </VStack>
          </HStack>
          <VStack w="30%">
            <Text isTruncated fontSize="sm" _light={{ color: 'coolGray.900' }}>
              {item.emailUUID}
            </Text>
          </VStack>
        </HStack>
      </Pressable>
  )
}

export function ListItemDesktop({
                                  item,
                                  isSelected,
                                  selectItem,
                                }: {
  item: EmailType;
  isSelected: boolean;
  selectItem: (i: EmailType) => any;
}) {
  return (
      <Pressable
          px={2}
          flex={1}
          _hover={{ bg: 'coolGray.100' }}
          onPress={() => selectItem(item)}
          bg={isSelected ? 'coolGray.200' : 'white'} // Change background color if selected
      >
        <HStack alignItems="center" flex={1} justifyContent="space-between">
          <VStack w="100%">
            <Text bold isTruncated>
              {item.name}
            </Text>
            <Text isTruncated ml={2}>
              {item.email}
            </Text>
            <Text isTruncated ml={2}>
              {item.emailUUID}
            </Text>
          </VStack>
        </HStack>
      </Pressable>
  )
}

export default function EmailListStaticCompact({
    emails,
    recordMetadata,
    selectItem,
}: {
    emails: EmailType[];
    recordMetadata: RecordMetadata;
    selectItem: (f: EmailType[]) => any;
}) {
    const [selectedItems, setSelectedItems] = useState<EmailType[]>([]);

    useEffect(() => {
        const initiallySelectedEmails = emails?.filter(email =>
            recordMetadata.emails?.includes(email.emailUUID)
        );
        setSelectedItems(initiallySelectedEmails);
        selectItem(initiallySelectedEmails);
    }, []);

    const toggleSelectItem = (item: EmailType) => {
        setSelectedItems((prevSelectedItems) => {
            const isSelected = prevSelectedItems?.some(
                selected => selected.emailUUID === item.emailUUID
            );

            let updatedSelectedItems;

            if (isSelected) {
                updatedSelectedItems = prevSelectedItems?.filter(
                    selected => selected.emailUUID !== item.emailUUID
                );
            } else {
                updatedSelectedItems = [...prevSelectedItems, item];
            }

            selectItem(updatedSelectedItems);

            return updatedSelectedItems;
        });
    };

    const isSelected = (item: EmailType) =>
        selectedItems?.some(selected => selected.emailUUID === item.emailUUID);

    return (
        <>
            <VStack
                px={{ base: 4, md: 8 }}
                py={{ base: 2, md: 8 }}
                borderRadius={{ md: '8' }}
                _light={{
                    borderColor: 'coolGray.200',
                    bg: { base: 'white' },
                }}
                borderWidth={{ md: '1' }}
                borderBottomWidth="1"
                space="4"
            >
                <Box>
                    <ScrollView>
                        <Box position="relative" display={{ md: 'none', base: 'flex' }}>
                            {emails.map((item: EmailType) => {
                                return (
                                    <ListItem
                                        item={item}
                                        key={item.emailUUID}
                                        isSelected={isSelected(item)}
                                        selectItem={toggleSelectItem}
                                    />
                                );
                            })}
                        </Box>
                        <Box display={{ md: 'flex', base: 'none' }}>
                            <VStack mt={3} space={3}>
                                {emails.map((item: EmailType) => {
                                    return (
                                        <ListItemDesktop
                                            item={item}
                                            key={item.emailUUID}
                                            isSelected={isSelected(item)}
                                            selectItem={toggleSelectItem}
                                        />
                                    );
                                })}
                            </VStack>
                        </Box>
                    </ScrollView>
                </Box>
            </VStack>
        </>
    );
}
