import { Box, Center, HStack, Icon, Pressable, VStack } from 'native-base'
import React from 'react'

import { MaterialIcons } from '@expo/vector-icons'
import DashboardLayout from 'components/DashboardLayout'
import { t } from 'i18n-js'
import { RootStackScreenProps } from 'utils/formDesigner/navigation'

export default function ({ route, navigation }: RootStackScreenProps<'Home'>) {
  return (
    <DashboardLayout
      title={t('menu.home')}
      displaySidebar={false}
      displayScreenTitle={false}
      backButton={false}
      navigation={navigation}
    >
      <VStack
        safeAreaBottom
        height="90%"
        borderRadius={{ md: '8' }}
        borderColor="coolGray.200"
        bg="white"
        px={{
          base: 4,
          md: 32,
        }}
      >
        <HStack pt="5" space={3} justifyContent="center">
            <Pressable
                onPress={() => navigation.navigate('FormList', route.params)}
            >
                <Center h="80" w="190" bg="primary.600" rounded="md" shadow={3}>
                    <VStack space={3}>
                        <Center>
                            <Icon
                                as={<MaterialIcons name="analytics" />}
                                color="white"
                                size={16}
                            />
                        </Center>
                        <Box
                            _text={{
                                fontWeight: 'bold',
                                fontSize: 'lg',
                                color: 'white',
                                textAlign: 'center',
                            }}
                        >
                            {t('home.find-statistic')}
                        </Box>
                    </VStack>
                </Center>
            </Pressable>
        </HStack>
      </VStack>
    </DashboardLayout>
  )
}
