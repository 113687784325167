import React from 'react'
import {useSignOut, useUserLocations} from 'utils/store'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { RootStackParamList } from 'utils/provider/navigation'
import Home from 'screens/researcher/Home'
import Statistic from "./Statistic";
import FormList from "./FormList";
import Statistics from './Statistics'

const RootStack = createStackNavigator<RootStackParamList>()

export default function AppResearcher({}: {}) {
    const [signOut] = useSignOut()
    useUserLocations()
    return (
        <NavigationContainer>
            <RootStack.Navigator initialRouteName="Home">
                <RootStack.Screen
                    name="Home"
                    component={Home}
                    options={{ headerShown: false }}
                />
                <RootStack.Screen
                    name="Statistics"
                    component={Statistics}
                    options={{ headerShown: false }}
                />
                <RootStack.Screen
                    name="Statistic"
                    component={Statistic}
                    options={{ headerShown: false }}
                />
                <RootStack.Screen
                    name="FormList"
                    component={FormList}
                    options={{ headerShown: false }}
                />
            </RootStack.Navigator>
        </NavigationContainer>
    )
}
