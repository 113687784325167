import { Feather, MaterialIcons } from '@expo/vector-icons'
import { StackNavigationProp } from '@react-navigation/stack'
import { getLocationCached } from 'api/common'
import {
  confirmUserEmail,
  createUser,
  resendUserConfirmationEmail,
  resetUserPassword,
  updateUser,
} from 'api/manager'
import { standardHandler } from 'api/utils'
import AnyCountry from 'components/AnyCountry'
import DateTimePicker from 'components/DateTimePicker'
import FloatingLabelInput from 'components/FloatingLabelInput'
import Language from 'components/Language'
import Loading from 'components/Loading'
import LocationListStatic from 'components/LocationListStatic'
import NecessaryItem from 'components/NecessaryItem'
import Photo from 'components/form-parts/Photo'
import { t } from 'i18n-js'
import _ from 'lodash'
import {
  Badge,
  Button,
  Center,
  CheckIcon,
  CloseIcon,
  HStack,
  Heading,
  Icon,
  Select,
  Tooltip,
  VStack, View, Text,
} from 'native-base'
import React, {useEffect, useState} from 'react'
import { dataURItoBlob } from 'utils/data'
import { useInfo } from 'utils/errors'
import { RootStackParamList } from 'utils/manager/navigation'
import { useUserLocationIDs } from 'utils/store'
import { LocationType } from 'utils/types/location'
import { UserType, splitLocations, userTypes } from 'utils/types/user'
import { useLanguage } from './LanguagePicker'

const dummyDate = new Date()

async function sendImageLink(user: Partial<UserType>, imageLink: any) {
  if (
    user.official_id_image &&
    !_.startsWith(user.official_id_image, 'https://')
  ) {
    let form = new FormData()
    for (const field in imageLink.fields) {
      form.append(field, imageLink.fields[field])
    }
    form.append('file', dataURItoBlob(user.official_id_image))
    await fetch(imageLink.url, {
      method: 'POST',
      headers: {},
      body: form,
    })
  }
}

export default function UserEditor({
  files,
  user,
  setUser,
  changed,
  navigation,
  reloadPrevious,
}: {
  files: Record<string, any>
  user: Partial<UserType>
  setUser: React.Dispatch<React.SetStateAction<Partial<UserType>>>
  changed: boolean
  navigation: StackNavigationProp<RootStackParamList, 'EditUser'>
  reloadPrevious: React.MutableRefObject<boolean>
}) {
  const [error, warning, success] = useInfo()
  const [waiting, setWaiting] = useState(null as null | string)
  const standardReporters = { setWaiting, error, warning, success }
  const [errors, setErrors] = useState<Partial<Record<keyof UserType, string>>>({});

  const createMode = !(user.userUUID && user.userUUID !== '')
  const { language } = useLanguage()

  const handleCreateUser = () => {
    if (!validateForm()) return;
    standardHandler(
        standardReporters,
        'Creating user',
        'User created',
        async () => {
          const r = await createUser(
              //@ts-ignore We validate this before the call
              user
          )
          sendImageLink(user, r.imageLink)
          setUser(r.user)
          reloadPrevious.current = true
        }
    )
  }

  const submitUser = (
    updatedUser: Partial<UserType>,
    inProgressMessage?: string,
    message?: string
  ) => {
    if (!validateForm()) return;
    standardHandler(
        standardReporters,
        inProgressMessage || t('user.submitting-user'),
        message || t('user.submitted-user'),
        async () => {
          const r = await updateUser(
              //@ts-ignore We validate this before the call
              updatedUser
          )
          sendImageLink(updatedUser, r.imageLink)
          setUser(r.user)
          reloadPrevious.current = true
        }
    )
  }

  const validateForm = (metadata: Partial<UserType> = user) => {
    const newErrors: Partial<Record<keyof UserType, string>> = {};

    if (!metadata.formal_name) {
      newErrors.formal_name = t('form-editor.required');
    }

    if (!metadata.username) {
      newErrors.username = t('form-editor.required');
    }

    if (!metadata.userType) {
      newErrors.userType = t('form-editor.required');
    }

    if (!metadata.expiry_date) {
      newErrors.expiry_date = t('form-editor.required');
    }

    if (!metadata.name) {
      newErrors.name = t('form-editor.required');
    }

    if (!metadata.nickname) {
      newErrors.name = t('form-editor.required');
    }

    if (!metadata.nickname) {
      newErrors.nickname = t('form-editor.required');
    }

    if (!metadata.email) {
      newErrors.email = t('form-editor.required');
    }

    if (!metadata.phone_number) {
      newErrors.phone_number = t('form-editor.required');
    }

    if (!metadata.gender) {
      newErrors.gender = t('form-editor.required');
    }

    if (!metadata.birthdate) {
      newErrors.birthdate = t('form-editor.required');
    }

    if (!metadata.official_id_type) {
      newErrors.official_id_type = t('form-editor.required');
    }

    if (!metadata.official_id_expires) {
      newErrors.official_id_expires = t('form-editor.required');
    }

    if (!metadata.official_id_code) {
      newErrors.official_id_code = t('form-editor.required');
    }

    if (!metadata.address) {
      newErrors.address= t('form-editor.required');
    }
    if (!metadata.country) {
      newErrors.country = t('form-editor.required');
    }

    if (!metadata.language) {
      newErrors.language = t('form-editor.required');
    }
    setErrors(newErrors);

    return _.isEmpty(newErrors);
  };

  const [locations, setLocations] = useState({} as Record<string, LocationType>)
  useEffect(() => {
    async function fn() {
      try {
        const l = {} as Record<string, LocationType>
        for (const lID of splitLocations(user.allowed_locations)) {
          if (lID === 'admin') continue
          const r = await getLocationCached(lID, () => null)
          if (r) l[lID] = r
        }
        setLocations(l)
      } catch (e) {
        error('Failed to locations')
      } finally {
        setWaiting(null)
      }
    }
    fn()
  }, [splitLocations(user.allowed_locations)])

  const handleSubmitUser = () => submitUser(user)

  const toggleUser = () => {
    if (!validateForm()) return;
    const newUser = { ...user, enabled: !user.enabled }
    setUser(newUser)
    submitUser(newUser)
  }

  const handleInputChange = (key: keyof UserType, value: string) => {
    setUser(prevState => {
      const updatedFormMetadata = { ...prevState, [key]: value };
      validateForm(updatedFormMetadata);
      return updatedFormMetadata;
    });
  };


  const resetPassword = async () =>
    standardHandler(
      standardReporters,
      t('user.password-resetting'),
      t('user.password-reset'),
      async () => {
        await resetUserPassword(user)
      }
    )

  const confirmEmail = async () =>
    standardHandler(
      standardReporters,
      t('user.confirming-email'),
      t('user.email-confirmed'),
      async () => {
        await confirmUserEmail(user)
        user.email_verified = 'true'
      }
    )

  const resendConfirmationEmail = async () =>
    standardHandler(
      standardReporters,
      t('user.resending-confirmation-email'),
      t('user.resent-confirmation-email'),
      async () => {
        await resendUserConfirmationEmail(user)
      }
    )

  const editorAllowedLocationIDs = useUserLocationIDs()

  return (
    <>
      <VStack>
        <FloatingLabelInput
          label={t('user.full-official-name')}
          value={user.formal_name}
          setValue={v => handleInputChange('formal_name', v)}
          error={errors.formal_name}
          mt={10}
        />
        <HStack alignItems="center" justifyContent="space-between">
          <FloatingLabelInput
            label={
              t('user.username') +
              (createMode ? '' : ' (' + t('common.read-only') + ')')
            }
            w="100%"
            containerW="45%"
            value={user.username}
            setValue={v => handleInputChange('username', v)}
            error={errors.username}
            isReadOnly={!createMode}
            placeholder={user.username}
          />
          {createMode ? (
            <View position={'relative'}>
              <Select
                size="md"
                selectedValue={user.userType}
                placeholder={t('user.user-type')}
                w="95%"
                onValueChange={itemValue => {
                  if (itemValue != null) {
                    handleInputChange('userType', itemValue)
                  }
                }}
                borderColor={errors.userType ? 'red.500' : 'coolGray.200'}
              >
                {userTypes.map((e, i) => (
                  <Select.Item
                    size="md"
                    key={e}
                    label={t('user.' + e)}
                    value={e}
                  />
                ))}
              </Select>
              {errors.userType && (
                  <Text color="red.500" mt={1} fontSize="xs" position={'absolute'} left={0} top={10}>
                    {t(`error.${errors.userType}`)}
                  </Text>
              )}
            </View>
          ) : (
            <FloatingLabelInput
              label={t('user.user-type') + ' (' + t('common.read-only') + ')'}
              w="100%"
              containerW="45%"
              isReadOnly
              placeholder={t('user.' + user.userType)}
            />
          )}
        </HStack>
        {user.userType === 'Manager' && (
          <Center>
            <HStack py={2} space={5}>
              {_.includes(user.allowed_locations, 'admin') && (
                <Badge
                  colorScheme="red"
                  _text={{
                    fontSize: 16,
                  }}
                  alignSelf="center"
                  variant="subtle"
                  mt={-1}
                >
                  {t('user-editor.user-administrator')}
                </Badge>
              )}
              {_.includes(editorAllowedLocationIDs, 'admin') &&
                (_.includes(user.allowed_locations, 'admin') ? (
                  <Button
                    leftIcon={
                      <Icon as={Feather} name="alert-triangle" size="sm" />
                    }
                    bg="warning.500"
                    onPress={() =>
                      setUser(u => {
                        return { ...u, allowed_locations: '' }
                      })
                    }
                    _text={{ selectable: false }}
                    mb={2}
                  >
                    {t('user-editor.revoke-admin-permissions')}
                  </Button>
                ) : (
                  <Button
                    leftIcon={
                      <Icon as={Feather} name="alert-triangle" size="sm" />
                    }
                    bg="error.500"
                    onPress={() =>
                      setUser(u => {
                        return { ...u, allowed_locations: '' }
                      })
                    }
                    _text={{ selectable: false }}
                    mb={2}
                  >
                    {t('user-editor.make-user-an-admin')}
                  </Button>
                ))}
            </HStack>
          </Center>
        )}
        {!_.includes(user.allowed_locations, 'admin') && (
          <VStack py={2}>
            <Center pb={2}>
              <Button
                bg={'info.500'}
                leftIcon={<Icon as={Feather} name="plus-square" size="sm" />}
                onPress={() => {
                  navigation.push('FindLocation', {
                    onSelect: async l => {
                      setUser(u => {
                        return {
                          ...u,
                          allowed_locations: _.join(
                            _.uniq(
                              _.concat(splitLocations(user.allowed_locations), [
                                l.locationUUID,
                              ])
                            ),
                            ' '
                          ),
                        }
                      })
                      navigation.goBack()
                    },
                  })
                }}
                _text={{ selectable: false }}
              >
                {t('user-editor.add-allowed-location')}
              </Button>
            </Center>
            {splitLocations(user.allowed_locations) !== ['admin'] &&
            !_.isEmpty(splitLocations(user.allowed_locations)) ? (
              <LocationListStatic
                locations={_.map(splitLocations(user.allowed_locations), l =>
                  l in locations ? locations[l] : l
                )}
                selectItem={l => {
                  setUser(u => {
                    return {
                      ...u,
                      allowed_locations: _.join(
                        _.without(
                          splitLocations(user.allowed_locations),
                          l.locationUUID
                        ),
                        ' '
                      ),
                    }
                  })
                }}
              />
            ) : (
              <Center>
                <Heading size="md" color="red.600">
                  {t('user-editor.add-permissions-for-location')}
                </Heading>
              </Center>
            )}
          </VStack>
        )}
        <Center>
          <DateTimePicker
            isDisabled={false}
            title={t('user.expiry-date')}
            fancyLabel={t('user.expiry-date')}
            date={user.expiry_date}
            open={() => false}
            close={() => false}
            setDate={(date: Date)  => handleInputChange('expiry_date', date)}
            error={errors.expiry_date}
          />
        </Center>
        {!createMode && (
          <Center>
            <NecessaryItem
              isDone={user.enabled || false}
              todoText={t('user.userDisabled')}
              doneText={t('user.userEnabled')}
              size={4}
            />
          </Center>
        )}
        <Center pt={4} pb={2}>
          <Heading size="md" fontWeight="normal" px={2}>
            {t('user.heading.bio')}
          </Heading>
        </Center>
        <HStack alignItems="center" justifyContent="space-between">
          <FloatingLabelInput
            label={t('user.short-name')}
            w="100%"
            containerW="45%"
            value={user.name}
            setValue={v => handleInputChange('name', v)}
            error={errors.name}
          />
          <FloatingLabelInput
            label={t('user.nickname')}
            w="100%"
            containerW="45%"
            value={user.nickname}
            setValue={v => handleInputChange('nickname', v)}
            error={errors.nickname}
          />
        </HStack>
        <HStack alignItems="center" justifyContent="space-between">
          <FloatingLabelInput
            label={t('user.email')}
            w="100%"
            containerW="45%"
            value={user.email}
            setValue={v => handleInputChange('email', v)}
            error={errors.email}
          />
          <FloatingLabelInput
            label={t('user.phone-number-with-help')}
            w="100%"
            containerW="45%"
            value={user.phone_number}
            setValue={v => handleInputChange('phone_number', v)}
            error={errors.phone_number}
          />
        </HStack>
        <FloatingLabelInput
          label={t('user.address')}
          value={user.address}
          setValue={v => handleInputChange('address', v)}
          error={errors.address}
        />
        <HStack alignItems="center" justifyContent="space-between">
          <FloatingLabelInput
            label={t('user.gender')}
            w="100%"
            containerW="45%"
            value={user.gender}
            setValue={v => handleInputChange('gender', v)}
            error={errors.gender}
          />
          <DateTimePicker
            isDisabled={false}
            title={t('user.birthday')}
            fancyLabel={t('user.birthday')}
            date={user.birthdate}
            open={() => false}
            close={() => false}
            setDate={(date: Date) => handleInputChange('birthdate', date)}
            error={errors.birthdate}
          />
        </HStack>
        <HStack alignItems="center" justifyContent="space-between">
          <AnyCountry
            placeholder={t('location.select-country')}
            value={user.country}
            setValue={v => handleInputChange('country', v)}
            error={errors.country}
            mx={3}
            mt={1}
            mb={3}
          />
          <Language
            placeholder={t('location.select-default-language')}
            value={user.language}
            setValue={v => handleInputChange('language', v)}
            error={errors.language}
            mx={3}
            mt={1}
            mb={3}
          />
        </HStack>
        <Center pt={4} pb={2}>
          <Heading size={'md'} fontWeight={'normal'} px={2}>
            {t('user.heading.official-id')}
          </Heading>
        </Center>
        <HStack alignItems="center" justifyContent="space-between">
          <FloatingLabelInput
            label={t('user.official_id_type')}
            w="100%"
            containerW="45%"
            value={user.official_id_type}
            setValue={v => handleInputChange('official_id_type', v)}
            error={errors.official_id_type}
          />
          <DateTimePicker
            isDisabled={false}
            // @ts-ignore TODO Why not?
            title={t('user.official_id_expires')}
            fancyLabel={t('user.official_id_expires')}
            date={user.official_id_expires}
            open={() => false}
            close={() => false}
            setDate={(date: Date) => handleInputChange('official_id_expires', date)}
            error={errors.official_id_expires}
          />
        </HStack>
        <FloatingLabelInput
          label={t('user.official_id_code')}
          value={user.official_id_code}
          setValue={v => handleInputChange('official_id_code', v)}
          error={errors.official_id_code}
        />
        <HStack alignItems="center" justifyContent="space-between">
          <Photo
            photos={
              user.official_id_image && user.official_id_image !== 'none'
                ? [{ uri: user.official_id_image, 'date-taken': dummyDate }]
                : []
            }
            addPhoto={p => setUser({ ...user, official_id_image: p.uri })}
            removePhoto={p => setUser({ ...user, official_id_image: 'none' })}
            isDisabled={false}
            onlyOne={true}
          />
        </HStack>
        <Center pt={4} pb={2}>
          <Heading size={'md'} fontWeight={'normal'} px={2}>
            {t('user.heading.metadata')}
          </Heading>
        </Center>
        <HStack alignItems="center" justifyContent="space-between">
          <FloatingLabelInput
            label={
              t('user.id') + ' (' + t('common.automatically-created') + ')'
            }
            w="100%"
            containerW="45%"
            isReadOnly
            placeholder={user.userID ? user.userID : t('form.not-yet-created')}
          />
          <FloatingLabelInput
            isReadOnly
            label={
              t('user.created-on') +
              ' (' +
              t('common.automatically-created') +
              ')'
            }
            placeholder={
              user && user.created_time
                ? user.created_time.toString()
                : t('form.not-yet-created')
            }
            w="100%"
            containerW="45%"
          />
        </HStack>
        <HStack alignItems="center" justifyContent="space-between">
          <FloatingLabelInput
            isReadOnly
            label={
              t('user.last-changed') +
              ' (' +
              t('common.automatically-created') +
              ')'
            }
            placeholder={
              user.last_updated_time
                ? user.last_updated_time.toString()
                : t('form.not-yet-created')
            }
            w="100%"
            containerW="45%"
          />
        </HStack>
        {createMode ? (
          <HStack my={5} justifyContent="center">
            <Button
              leftIcon={<Icon as={MaterialIcons} name="save" size="sm" />}
              colorScheme="green"
              onPress={handleCreateUser}
            >
              {t('user.create-user')}
            </Button>
          </HStack>
        ) : (
          <VStack>
            <Center>
              <HStack my={5} justifyContent="space-between">
                {user.status === 'FORCE_CHANGE_PASSWORD' &&
                  user.email_verified !== 'true' && (
                    <Button
                      colorScheme="info"
                      onPress={resendConfirmationEmail}
                    >
                      {t('user.resend-confirmation-email')}
                    </Button>
                  )}
                {user.status === 'CONFIRMED' && user.email_verified !== 'true' && (
                  <Button
                    leftIcon={
                      <Icon as={MaterialIcons} name="check" size="sm" />
                    }
                    colorScheme="orange"
                    onPress={confirmEmail}
                  >
                    {t('user.confirm-email')}
                  </Button>
                )}
              </HStack>
            </Center>
            <HStack my={5} justifyContent="space-between">
              <Button
                leftIcon={<Icon as={MaterialIcons} name="save" size="sm" />}
                colorScheme="green"
                onPress={handleSubmitUser}
              >
                {t('user.submit-user')}
              </Button>
              {user.email_verified === 'true' && (
                <Button
                  leftIcon={<Icon as={MaterialIcons} name="lock" size="sm" />}
                  colorScheme="orange"
                  onPress={resetPassword}
                >
                  {t('user.reset-password')}
                </Button>
              )}
              <Tooltip openDelay={0} label="Submit first" isDisabled={!changed}>
                <Button
                  leftIcon={
                    user.enabled ? (
                      <CloseIcon size={'5'} mx={2} />
                    ) : (
                      <CheckIcon size={'5'} mx={2} />
                    )
                  }
                  colorScheme={user.enabled ? 'red' : 'green'}
                  onPress={toggleUser}
                >
                  {user.enabled
                    ? t('user.disable-user')
                    : t('user.enable-user')}
                </Button>
              </Tooltip>
            </HStack>
          </VStack>
        )}
      </VStack>
      <Loading loading={waiting} />
    </>
  )
}
