// Do not edit the generated code by hand. Change the template and rerun update-config.sh

export default {
    apiGateway: {
        provider: {
            REGION: "us-east-1",
            URL: "https://0k5q6vt11j.execute-api.us-east-1.amazonaws.com/development"
        },
        associate: {
            REGION: "us-east-1",
            URL: "https://picniyoru8.execute-api.us-east-1.amazonaws.com/development"
        },
        manager: {
            REGION: "us-east-1",
            URL: "https://dr0b9a9ha1.execute-api.us-east-1.amazonaws.com/development"
        },
        formdesigner: {
            REGION: "us-east-1",
            URL: "https://uuw8wiwiqf.execute-api.us-east-1.amazonaws.com/development"
        },
        researcher: {
            REGION: "us-east-1",
            URL: "https://o6v7lzfx52.execute-api.us-east-1.amazonaws.com/development"
        }

    },
    cognito: {
        provider: {
            REGION: "us-east-1",
            USER_POOL_ID: "us-east-1_yeBjCNmRw",
            IDENTITY_POOL_ID: "us-east-1:2b1db6de-1754-43b4-8576-a49e79f10368",
            APP_CLIENT_ID: "jrq2asht5j8ts4ftlfd6am9b7"
        },
        associate: {
            REGION: "us-east-1",
            USER_POOL_ID: "us-east-1_dvwDn8VFO",
            IDENTITY_POOL_ID: "us-east-1:e319978a-e5a8-4986-998e-abc6270aadc5",
            APP_CLIENT_ID: "75ot2s8a9ptotivd5uliiu1h74"
        },
        formdesigner: {
            REGION: "us-east-1",
            USER_POOL_ID: "us-east-1_16YsMcqBZ",
            IDENTITY_POOL_ID: "us-east-1:07ed2180-e8cf-45a8-902f-d0ed3c5c083a",
            APP_CLIENT_ID: "33l3l1g7j450tcq7qcsh352402"
        },
        researcher: {
            REGION: "us-east-1",
            USER_POOL_ID: "us-east-1_yjabem6bO",
            IDENTITY_POOL_ID: "us-east-1:e65d56f5-6625-49c1-8408-fc085841f3d3",
            APP_CLIENT_ID: "1i0k8bmf6dfivnp295bp9fales"
        },
        manager: {
            REGION: "us-east-1",
            USER_POOL_ID: "us-east-1_VHrHNMAZa",
            IDENTITY_POOL_ID: "us-east-1:f277834d-5116-4854-8a50-6037110611e6",
            APP_CLIENT_ID: "49sjhgp3h7g64eeq3v1jv2q994"
        },
    }
};
