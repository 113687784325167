import React from 'react';
import { Select, ISelectProps, ISelectItemProps, Box, View, Text } from 'native-base';
import { t } from 'i18n-js';
import { useWindowDimensions } from 'react-native';
import _ from 'lodash';

export const languages = ['en', 'fr'];

export default function Language({
  placeholder,
  value,
  setValue,
  bg,
  any,
  itemProps,
  error,
  ...props
}: {
  placeholder: string;
  value: string | undefined;
  setValue: (val: string) => any;
  bg?: string;
  any?: string;
  itemProps?: ISelectItemProps;
  error?: string;
} & ISelectProps) {
  const { width } = useWindowDimensions();

  return (
      <View position={'relative'}>
        <Select
            bg={bg}
            selectedValue={value}
            placeholder={placeholder}
            onValueChange={setValue}
            borderColor={error ? 'red.500' : 'coolGray.200'}
            width={width < 440 ? 150 : '100%'}
            // overflowX={'hidden'}
            {...props}
        >
          {_.concat(
              any
                  ? [
                    <Select.Item
                        key={'__any__'}
                        label={t(any)}
                        value={''}
                        {...itemProps}
                    />,
                  ]
                  : [],
              languages.map((e) => (
                  <Select.Item
                      key={e}
                      label={t('languages.' + e)}
                      value={e}
                      {...itemProps}
                  />
              ))
          )}
        </Select>
        {error && (
            <Text color="red.500" mt={1} fontSize="xs" position={'absolute'} left={3} top={10}>
              {t(`error.${error}`)}
            </Text>
        )}
      </View>
  );
}
