import { schemaVersions } from 'api/utils'
import { API } from 'aws-amplify'
import _ from 'lodash'
import {
  FormMetadata,
  formMetadataSchema,
  FormRecMetadata,
} from 'utils/types/formMetadata'
import { QueryFilterForType } from 'utils/types/url'
import {StatisticsForPostType, StatisticsType} from "../utils/types/statistics";

export async function findForms(
  pre: () => any,
  post: () => any,
  filterCountry: string | undefined,
  filterLanguage: string | undefined,
  filterLocationID: string | undefined,
  filterEnabled: string | undefined,
  filterSearchType: string | undefined,
  filterText: string | undefined,
  filterFormType: string | undefined,
  handleErrors: (err: any) => any,
  setForms: (users: FormMetadata[]) => any,
  setNextKey: (key: string) => any
) {
  try {
    pre()
    let filters: QueryFilterForType<FormRecMetadata> = []
    if (filterCountry) filters.push({ country: { eq: filterCountry } })
    if (filterLanguage) filters.push({ language: { eq: filterLanguage } })
    if (filterLocationID) filters.push({ locationID: { eq: filterLocationID } })
    if (filterEnabled) filters.push({ enabled: { eq: filterEnabled } })
    if (filterFormType) filters.push({formType: {eq: filterFormType}})
    if (filterSearchType) filters.push({searchType: {eq: filterSearchType}})
    if (filterText) { // @ts-ignore
      filters.push({ [filterSearchType]: { contains: filterText } })
    }

    const data = await API.get('researcher', '/researcher/form', {
      queryStringParameters: {
        filter: JSON.stringify(filters),
      },
      headers: {
        AcceptedVersions: JSON.stringify(schemaVersions(formMetadataSchema)),
      },
    })
    // @ts-ignore TODO
    setForms(_.map(data.items, formMetadataSchema.parse))
    setNextKey(data.nextKey)
  } catch (e) {
    handleErrors(e)
  } finally {
    post()
  }
}

export async function getStatistic(formUUID: string): Promise<StatisticsType[]> {
  const data = await API.get(
      'researcher',
      '/researcher/statistics/' + formUUID,
      {
        headers: {
        },
      }
  )
  return data.items
}

export async function createStatistic(statistic: Partial<StatisticsForPostType>, formUUID: string | undefined) {
  const data = await API.post('researcher', '/researcher/statistics/' + formUUID, {
    body: statistic,
    headers: {},
  })
  return data
}