import {t} from "i18n-js";
import React, {useEffect, useRef, useState} from 'react';
import {Text, StyleSheet} from 'react-native';
import {Button, CheckIcon, HStack, Modal, Pressable, ScrollView, Select} from 'native-base';
import DashboardLayout from '../../components/DashboardLayout';
import { RootStackScreenProps } from '../../utils/formDesigner/navigation';
import {createStatistic, getStatistic} from "../../api/researcher";
import {StatisticsForPostType, StatisticsType} from "../../utils/types/statistics";
import _ from "lodash";
import {standardHandler} from "../../api/utils";
import {useInfo} from "../../utils/errors";

export default function Statistic({ route, navigation }: RootStackScreenProps<'Statistics'>) {
    const latestVersion = useRef(route.params?.formMetadata?.version)

    const [statistic, setStatistic] = useState<StatisticsType[]>([]);
    const [modal, setModal] = useState<boolean>(false);
    const [day,setDay] = useState<string>('30');
    const [version,setVersion] = useState<string>(latestVersion.current);
    const [waiting, setWaiting] = useState('Loading' as null | string)
    const [error, warning, success] = useInfo()


    const reloadPrevious = useRef(false)

    const standardReporters = { setWaiting, error, warning, success }

    const doSearch = async () => {
        if (route.params?.formMetadata?.formUUID) {
            const r = await getStatistic(route.params?.formMetadata?.formUUID);
            setStatistic(r);
        }
    };

    useEffect(() => {
        doSearch();
    }, []);


    const submitStatistic = (
        statistic: Partial<StatisticsForPostType>,
        inProgressMessage?: string,
        message?: string
    ) => {
        standardHandler(
            standardReporters,
            inProgressMessage || t('statistic.submitting-statistic'),
            message || t('statistic.submit-statistic'),
            async () => {
                const r = await createStatistic(
                    statistic,
                    route.params?.formMetadata?.formUUID,
                )
                if (r.formId){
                    setModal(false)
                    doSearch()
                }
                reloadPrevious.current = true
            }
        )
    }

    return (
        <DashboardLayout
            navigation={navigation}
            displaySidebar={false}
            displayScreenTitle={false}
            title={t('statistic.select-statistic')}
        >
            <Button onPress={()=> setModal(true)}>
                {t('statistic.create-new-statistic')}
            </Button>
            <ScrollView>
                {statistic?.map((section, index) => {
                    const parsedData = JSON.parse(section.data);
                    return (
                        <Pressable key={index} style={styles.section} onPress={()=>{
                            navigation.navigate('Statistic', {
                                ...route.params,
                                parsedData,
                            })
                        }}>
                            <HStack>
                                <Text style={styles.statName}>{t('statistic.form-version')}</Text>
                                <Text style={styles.statValue}>{section.formVersion}</Text>
                            </HStack>
                            <HStack>
                                <Text style={styles.statName}>{t('statistic.created-at')}</Text>
                                <Text style={styles.statValue}>{section.createdAt}</Text>
                            </HStack>
                            <HStack>
                                <Text style={styles.statName}>{t('statistic.days-created')}</Text>
                                <Text style={styles.statValue}>{section.dateInterval}</Text>
                            </HStack>
                        </Pressable>
                    );
                })}
            </ScrollView>
            <Modal
                isOpen={modal}
                onClose={() => setModal(false)}
                size="lg"
            >
                <Modal.Content>
                    <Modal.CloseButton />
                    <Modal.Header>
                        {t('statistic.create-new-statistic')}
                    </Modal.Header>
                    <Modal.Body p={10}>
                        <Select
                            selectedValue={day}
                            minWidth="100"
                            accessibilityLabel={t('statistic.select-day')}
                            placeholder={t('statistic.select-day')}
                            bg="white"
                            _selectedItem={{
                                bg: 'teal.600',
                                endIcon: <CheckIcon size="5" />,
                            }}
                            mt={1}
                            onValueChange={itemValue => setDay(itemValue)}
                        >
                            <Select.Item label={t('statistic.select.30day')} value="30" />
                            <Select.Item label={t('statistic.select.60day')} value="60" />
                            <Select.Item label={t('statistic.select.90day')} value="90" />
                        </Select>
                        <Select
                            marginTop={10}
                            selectedValue={version}
                            minWidth="100"
                            accessibilityLabel={t('statistic.select-version')}
                            placeholder={t('statistic.select-version')}
                            bg="white"
                            mt={1}
                            onValueChange={setVersion}
                        >
                            {_.map(_.range(1, 1 + parseInt(latestVersion.current)), v => (
                                <Select.Item label={`${t('statistic.version')} ` + v} value={'' + v} />
                            ))}
                        </Select>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button.Group space={2}>
                            <Button
                                variant="solid"
                                colorScheme="green"
                                onPress={() => {
                                    submitStatistic({
                                        formVersion: version,
                                        dateInterval: day
                                    })
                                }}
                                disabled={!day || !version}
                            >
                                {t('statistic.create-statistic')}
                            </Button>
                            <Button
                                variant="ghost"
                                colorScheme="red"
                                onPress={() => setModal(false)}
                            >
                                {t('record.buttons.cancel')}
                            </Button>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
        </DashboardLayout>
    );
}

const styles = StyleSheet.create({
    section: {
        marginTop: 20,
        padding: 20,
        backgroundColor: '#fff',
        borderRadius: 8,
    },
    statName: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    statValue: {
        fontSize: 16,
        fontWeight: 'normal',
        marginBottom: 5,
        paddingLeft: 5
    },
});
