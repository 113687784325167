import {findForms, findLocations} from 'api/formdesigner'
import { default as FormListComponent } from 'components/FormList'
import Loading from 'components/Loading'
import { t } from 'i18n-js'
import React, {useCallback, useEffect, useState} from 'react'
import { handleStandardErrors, useInfo } from 'utils/errors'
import { FormMetadata } from 'utils/types/formMetadata'
import { useLanguage } from './LanguagePicker'
import {useFocusEffect} from "@react-navigation/native";
import {LocationType} from "../utils/types/location";

export default function FormSearch({
  selectItem,
  formType,
  modal,
}: {
  selectItem: (fm: FormMetadata) => any
  formType?: string;
  modal?: boolean;
}) {
  const [forms, setForms] = useState([] as FormMetadata[])
  const [nextKey, setNextKey] = useState(undefined as any)
  const [nextKeyLocations, setNextKeyLocations] = useState(undefined as any)
  const [filterCountry, setFilterCountry] = useState('')
  const [filterLanguage, setFilterLanguage] = useState('')
  const [filterLocationID, setFilterLocationID] = useState('')
  const [filterEnabled, setFilterEnabled] = useState('')
  const [filterSearchType, setFilterSearchType] = useState('')
  const [filterText, setFilterText] = useState(undefined as undefined | string)
  const [error, warning, success] = useInfo()
  const [waiting, setWaiting] = useState(null as null | string)
  const { language } = useLanguage()
  const [filterFormType, _] = useState(formType ? formType : "MAIN")
  const [locations, setLocations] = useState([] as LocationType[])

  const doSearch = async () => {
    findForms(
      () => setWaiting(t('alert.searching')),
      () => setWaiting(null),
      filterCountry,
      filterLanguage,
      filterLocationID,
      filterFormType === 'ADDENDUM' ? 'enabled' : filterEnabled,
      filterSearchType,
      filterText,
      filterFormType,
      e => handleStandardErrors(error, warning, success, e),
      setForms,
      setNextKey
    )
  }

  useEffect(() => {
    doSearch()
  }, [
    filterCountry,
    filterLanguage,
    filterLocationID,
    filterEnabled,
    filterSearchType,
    filterText,
  ])

  const doSearchLocations = async () => {
    findLocations(
        () => setWaiting(t('alert.loading')),
        () => setWaiting(null),
        filterCountry,
        '',
        '',
        '',
        'enabled',
        e => handleStandardErrors(error, warning, success, e),
        setLocations,
        setNextKeyLocations
    )
  }

  useEffect(() => {
    doSearchLocations()
  }, [filterCountry])

  if (!modal) {
    useFocusEffect(
        useCallback(() => {
          doSearch()
        }, [])
    )
  }

  return (
    <>
      <FormListComponent
        forms={forms}
        locations={locations}
        hasMore={false}
        loadMore={() => null}
        filterCountry={filterCountry}
        setFilterCountry={setFilterCountry}
        filterLanguage={filterLanguage}
        setFilterLanguage={setFilterLanguage}
        filterLocationID={filterLocationID}
        setFilterLocationID={setFilterLocationID}
        filterEnabled={filterEnabled}
        setFilterEnabled={setFilterEnabled}
        filterSearchType={filterSearchType}
        setFilterSearchType={setFilterSearchType}
        filterText={filterText}
        setFilterText={setFilterText}
        doSearch={doSearch}
        selectItem={selectItem}
      />
      <Loading loading={waiting} />
    </>
  )
}
